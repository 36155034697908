<template>
  <div>
    <!-- select 2 demo -->

    <div>
      <!-- Table Top -->
      <div>
        <b-sidebar
          id="sidebar-right"
          ref="mySidebar"
          bg-variant="white"
          v-model="visibility"
          sidebar-class="sidebar-lg"
          right
          no-header
          backdrop
          shadow
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">
              {{ sidebarTitle }}
            </h5>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hideSideBar()"
            />
          </div>
          <b-form class="p-2" @submit.prevent>
            <b-row class="mt-1">
              <!-- <b-col md="12" v-if="adding">
                <b-form-group
                  label="Exam Category"
                  invalid-feedback="Exam Category is required."
                  ref="name"
                >
                  <b-form-textarea
                    ref="name"
                    placeholder="Enter exam category by comma"
                    v-model="myObj.examType"
                    rows="3"
                  ></b-form-textarea>
                </b-form-group>
              </b-col> -->

              <b-col md="12">
                <b-form-group
                  label="Exam Category"
                  invalid-feedback="Exam Category is required."
                  ref="name"
                >
                  <b-form-input
                    id="mc-first-name"
                    placeholder="Enter exam category here"
                    name="classField"
                    ref="name"
                    v-model="myObj.examType"
                    @input="CheckName()"
                  />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label="Linked Exams"
                  invalid-feedback="Linked Exams is required."
                >
                  <v-select
                    multiple
                    :closeOnSelect="false"
                    v-model="selectedIDs"
                    :options="examOptions"
                    label="examType"
                    :reduce="(val) => val.id"
                    :clearable="false"
                    :disabled="editLoading"
                    placeholder="Select linked exams"
                  />
                </b-form-group>
              </b-col>

              <b-col md="12" v-if="wObj && wObj.valueBool">
                <div v-if="wData.length === 0" class="text-center mb-2">
                  <b-button
                    @click="addValue()"
                    variant="primary"
                    class="btn-icon"
                    pill
                    size="sm"
                  >
                    <feather-icon icon="PlusIcon" />
                    <span class="line-height-1 ml-25">Add Weightage</span>
                  </b-button>
                </div>

                <b-row v-for="(item, index) in wData" :key="index" class="mb-2">
                  <b-col md="12">
                    <b-form-group
                      label="Classes"
                      invalid-feedback="Class is required."
                    >
                      <!-- :options="classesOptions" -->
                      <v-select
                        multiple
                        :closeOnSelect="false"
                        v-model="item.clsIDs"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="getAvailableClasses(index)"
                        :clearable="false"
                        label="name"
                        :reduce="(val) => val.id"
                        :disabled="clsLoading"
                        placeholder="Select classes"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="5">
                    <b-form-group
                      label="Weightage"
                      invalid-feedback="Weightage is required."
                    >
                      <b-form-input
                        type="number"
                        v-model="item.weightage"
                        placeholder="Enter weightage"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="7" class="align-self-center">
                    <b-button
                      v-if="index === wData.length - 1"
                      @click="addValue()"
                      variant="primary"
                      class="btn-icon"
                      pill
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <!-- v-if="wData.length > 1" -->
                    <b-button
                      @click="removeValue(index)"
                      variant="outline-danger"
                      class="btn-icon ml-50"
                      pill
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>

              <!-- <b-col md="12" class="mb-1 d-flex">
                <b-button
                  v-for="t in typeOptions"
                  :key="t"
                  style="width: 50%"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :variant="
                    t.value == myObj.type ? 'primary' : 'outline-primary'
                  "
                  class="mr-1"
                  @click="myObj.type = t.value"
                >
                  {{ t.text }}
                </b-button>
              </b-col> -->

              <!-- <b-col md="12" v-if="myObj.type == 'exam'">
                <b-form-group
                  label="Date"
                  invalid-feedback="Date is required."
                  ref="date"
                >
                  <flat-pickr
                    :config="config"
                    v-model="myObj.date"
                    class="form-control"
                    placeholder="duration of exam"
                  />
                </b-form-group>
              </b-col> -->

              <b-col md="12" class="mt-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="success"
                  @click="Add()"
                  :disabled="request"
                  block
                >
                  <b-spinner v-if="request" small type="grow" />
                  <span v-else> {{ sidebarButton }} </span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <sidebar-content />
        </b-sidebar>

        <b-card>
          <b-row class="mt-1">
            <b-col xl="2" lg="3" md="4" sm="12" cols="12" v-if="rights.add">
              <b-button
                @click="AddOpen()"
                block
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
              >
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle">Exam Category</span>
              </b-button>
            </b-col>

            <b-col
              class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
              xl="10"
              lg="9"
              md="8"
              sm="12"
              cols="12"
            >
              <b-form-group class="">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>

                  <b-form-input v-model="searchQuery" placeholder="Search...">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-table
            class="mt-1"
            ref="selectableTable"
            :items="filters"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :busy="dataLoading"
            show-empty
            responsive
            hover
          >
            <!-- :selectable="rights.edit"
            select-mode="single"
            @row-selected="Edit($event[0].id)" -->
            <template #empty="scope">
              <h3 class="mt-1 mb-1" style="text-align: center">
                No records found
              </h3>
            </template>
            <template #table-busy>
              <div
                class="d-flex justify-content-center mb-3"
                style="margin-top: 50px"
              >
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  type="grow"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </div>
            </template>
            <template #cell(name)="data">
              <b-badge style="margin-inline: 2px" variant="light-primary">
                {{ data.item.name }}
              </b-badge>
            </template>

            <template #head(actions)="data">
              <div class="text-center">
                <span>{{ data.label }}</span>
              </div>
            </template>

            <template #cell(actions)="data">
              <div class="text-center">
                <b-button
                  v-if="rights.edit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  v-b-tooltip.hover.left
                  title="Edit"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Edit(data.item.id)"
                  :disabled="editLoading && currentID == data.item.id"
                >
                  <b-spinner
                    v-if="editLoading && currentID == data.item.id"
                    small
                  />
                  <feather-icon v-else icon="EditIcon" class="" />
                </b-button>

                <b-button
                  v-if="rights.delete"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  v-b-tooltip.hover.right
                  title="Delete"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Delete(data.item.id)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </div>
            </template>
          </b-table>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="filters.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    flatPickr,
    BFormTextarea,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
        };
      });
    });
    // console.log(right);
    this.$store.commit("setRights", right);

    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadData();
      this.LoadSettings();
    }
  },
  computed: {
    filters: function () {
      return this.items.filter((pro) => {
        return pro.examType.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      rights: {},
      request: false,
      adding: false,
      sidebarTitle: "Add Exam Type",
      sidebarButton: "Save",
      dataLoading: false,
      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      typeOptions: [
        { text: "Exam", value: "exam" },
        { text: "Test", value: "test" },
      ],
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        mode: "range",
      },
      fields: [
        { label: "name", key: "examType" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      myObj: {
        id: 0,
        examType: "",
        status: "active",
        campusID: this.$store.state.userData.cId,
        linkedIDs: "",
      },
      wObj: null,
      clsLoading: false,
      classes: [],
      classesOptions: [],
      wData: [],
      currentID: 0,
      editLoading: false,
      selectedIDs: [],
      examOptions: [],
    };
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    getAvailableClasses(currentIndex) {
      // Get all selected class IDs except for the current entry
      const selectedClassIds = this.wData.reduce((acc, item, index) => {
        if (index !== currentIndex) {
          acc.push(...item.clsIDs);
        }
        return acc;
      }, []);

      // Return only classes that haven't been selected in other entries
      return this.classesOptions.filter(
        (cls) => !selectedClassIds.includes(cls.id)
      );
    },
    addValue() {
      this.wData.push({
        clsIDs: [],
        weightage: 0,
      });
    },
    removeValue(index) {
      this.wData.splice(index, 1);
    },

    async Edit(id) {
      this.editLoading = true;
      this.currentID = id;
      this.wData = [];

      if (this.wObj && this.wObj.valueBool) {
        var obj2 = {
          url:
            this.$store.state.domain +
            "ExamTypes/LoadWithWeightage?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&examID=" +
            id,
          token: this.$store.state.userData.token,
        };
        let res2 = await this.get(obj2);
        // console.log("res:", res2);
        if (res2 == "not found" || Array.isArray(res2)) {
          this.$bvToast.toast("Something went wrong!", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        } else {
          this.myObj = res2.examType;
          this.wData = res2.weightData ? res2.weightData : [];
          this.selectedIDs = this.myObj.linkedIDs
            ? this.myObj.linkedIDs
                .split(",")
                .filter((el) => el)
                .map(Number)
            : [];
          this.examOptions = this.items.filter((el) => el.id != id);
          this.adding = false;
          this.visibility = true;
          this.sidebarTitle = "Edit Exam Category";
          this.sidebarButton = "Update";
          var elem = this.$refs["name"];
          elem.state = undefined;
        }
      } else {
        var obj = {
          url:
            this.$store.state.domain +
            "ExamTypes/GetSelected?id=" +
            id +
            "&db=" +
            this.$store.state.userData.db,
          token: this.$store.state.userData.token,
        };
        let res = await this.get(obj);
        if (res == "NotFound" || Array.isArray(res)) {
          this.$bvToast.toast("Something went wrong!", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        } else {
          this.myObj = res;
          this.selectedIDs = this.myObj.linkedIDs
            ? this.myObj.linkedIDs
                .split(",")
                .filter((el) => el)
                .map(Number)
            : [];
          this.examOptions = this.items.filter((el) => el.id != id);
          this.adding = false;
          this.visibility = true;
          this.sidebarTitle = "Edit Exam Category";
          this.sidebarButton = "Update";
          var elem = this.$refs["name"];
          elem.state = undefined;
        }
      }
      // console.log("editObj:", this.myObj);
      this.editLoading = false;
      this.currentID = 0;
    },
    AddOpen() {
      this.myObj = {
        id: 0,
        examType: "",
        status: "active",
        linkedIDs: "",
        campusID: this.$store.state.userData.cId,
      };
      this.wData = [];
      this.selectedIDs = [];
      this.examOptions = [...this.items];
      this.adding = this.wObj && this.wObj.valueBool ? false : true;
      this.visibility = true;
      this.sidebarTitle = "Add Exam Category";
      this.sidebarButton = "Save";
      var elem = this.$refs["name"];
      elem.state = undefined;
    },
    hideSideBar() {
      this.visibility = false;
    },

    async LoadSettings() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: ["exam-weightage"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      this.wObj = res.find((el) => el.key == "exam-weightage");
      if (this.wObj && this.wObj.valueBool) this.LoadClasses();
    },
    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "ExamTypes?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      this.dataLoading = false;
      // console.log("exam cat", this.items);
    },
    async LoadClasses() {
      this.clsLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      this.classes = await this.get(obj);
      this.classesOptions = this.classes.map((el) => el.cls);

      this.clsLoading = false;
    },

    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.examType.trim() === "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkValues() {
      let state = true;
      if (this.wObj && this.wObj.valueBool) {
        this.wData.forEach((el) => {
          let x = parseInt(el.weightage);
          if (isNaN(x) || x <= 0 || el.clsIDs.length == 0) {
            state = false;
          } else el.weightage = x;
        });
      }
      return state;
    },
    async Add() {
      this.CheckName();
      if (this.CheckName() == false) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else if (this.checkValues() == false) {
        return this.$bvToast.toast(
          "Please add the weightage details correctly.",
          {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          }
        );
      } else {
        this.myObj.linkedIDs = this.selectedIDs.join(",");

        // console.log(this.myObj, this.wData);
        if (this.wObj && this.wObj.valueBool) {
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "ExamTypes/AddWithWeightage?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId,
            body: {
              examType: this.myObj,
              weightData: this.wData,
            },
            message: "Exam category saved successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility = false;
          if (status) this.LoadData();
        } else if (this.myObj.id == 0) {
          //Add
          this.request = true;
          // /AddMultiple
          var status = await this.post({
            url:
              this.$store.state.domain +
              "ExamTypes?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId +
              "&terms=" +
              this.myObj.examType,
            body: this.myObj,
            message: "Exam category added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility = false;
          if (status) this.LoadData();
        } else {
          //Edit
          this.request = true;
          // console.log("edit_obj:", this.myObj);
          var status = await this.put({
            url:
              this.$store.state.domain +
              "ExamTypes/" +
              this.myObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Exam category updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility = false;
          if (status) this.LoadData();
        }
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "ExamTypes/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Exam category removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
